export default {
  "Base": {
    "Links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gauche"])},
    "Rechts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit"])},
    "Verticaal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical"])},
    "Horizontaal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal"])},
    "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez visualisation"])},
    "greeplengte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longueur de la poignée"])}
  },
  "MainTitle": {
    "Deuren/panelen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portes/panneaux"])},
    "Kaderverdeling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croisillons"])},
    "Deurgrepen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poignées"])},
    "Glas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verre"])},
    "Extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra"])},
    "Color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur et finition"])},
    "Pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcul du prix"])}
  },
  "SubTitle": {
    "Producttype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de produit"])},
    "Deurtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de porte"])},
    "Draairichting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sens de rotation"])},
    "Deurmaten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensions de la porte/du panneau"])},
    "Panelen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre et dimensions des panneaux supplémentaires"])},
    "Draaipunt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance du pivot de la porte"])},
    "Aantal/positie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre/position croisillons"])},
    "Greeptype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de poignée"])},
    "Maten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensions"])},
    "Positie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position de la poignée"])},
    "MetMiddenslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec serrure au milieu"])},
    "Slotplaat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaque de verrouillage"])},
    "Glassoorten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types de verre"])},
    "Deurslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verrou dans la plinthe inférieure"])},
    "SlotPot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bague de serrure"])},
    "Cilinders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cylindre"])},
    "ALOType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de profil"])},
    "Opvulvlak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surface de rembourrage"])},
    "Ralkleur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur RAL"])},
    "Lakafwerking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finition de peinture"])},
    "Anodic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC"])},
    "Zijspeling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeu de côté"])},
    "AsTools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOLS"])},
    "Kantschuifslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verrou coulissant latéral"])}
  },
  "Producttype": {
    "Hydraulische": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte pivotante hydraulique"])},
    "Paneel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panneau fixe"])},
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les combinaisons de portes et de panneaux peuvent être configurées au fur et à mesure."])},
    "Sliding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte coulissante"])}
  },
  "Deurtype": {
    "Enkel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte simple"])},
    "Dubbel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double porte"])}
  },
  "Deurmaten": {
    "Hoogte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur porte/panneau"])},
    "Breedte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largeur porte/panneau"])},
    "Info01": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La baie pour les portes et les panneaux ci-dessus est: ", _interpolate(_list(0)), " mm x ", _interpolate(_list(1)), " mm."])},
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La hauteur maximale est 3000 mm!"])},
    "Error02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La largeur maximale est 1500 mm!"])},
    "Error03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le poids de la porte max. est 100kg! Veuillez réduire les dimensions de la porte svp."])}
  },
  "AsTools": {
    "TextTool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si la largeur de votre porte est supérieure à 1010 mm, il est recommandé d'acheter ASTOOL1 et ASTOOL2 pour régler la vitesse de fin et de fermeture et la broche supérieure."])},
    "TextToolXL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si la largeur de votre porte est supérieure à 1200 mm, il est recommandé d'acheter ASTOOL1XL et ASTOOL2XL pour régler la vitesse de fin et de fermeture et la broche supérieure."])},
    "AsTool1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL 1 - L: 300mm"])},
    "AsTool2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL 2 - L: 300mm"])},
    "AsTool1XL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL 1XL - L: 600mm"])},
    "AsTool2XL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL 2XL - L: 600mm"])}
  },
  "Panelen": {
    "PaneelNum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Panneaux ", _interpolate(_list(0)), " (mm)"])},
    "AfwijkendeSituatie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situations déviantes"])},
    "AfwijkendeSituatieLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://alusta.be/assets/pdf/afwijkende_panelen.pdf"])},
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La largeur maximale est 1500 mm"])},
    "Asymmetrisch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asymétrique"])},
    "TProfiel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilé en T"])},
    "IProfiel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilé en I"])},
    "ProfielInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quand vous choisissez une poignée AMUR SQ ou AMT et qu’ après cela vous positionner celle-ci sur un profil, nous vous conseillons de choisir le profil en I. Le profil en T est techniquement également possible mais cette solution plus chère et moins agréable à l’utilisation à cause du bord surélevé."])}
  },
  "Draaipunt": {
    "Afstand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance"])},
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distance suggérée est la distance recommandée pour les dimensions de porte ci-dessus."])},
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous recommandons de déplacer davantage le point de pivot. Le poste sélectionné n'est pas couvert par la garantie."])},
    "Error02": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Le point de pivot ne peut pas être supérieur à la moitié de la largeur de la porte (", _interpolate(_list(0)), " mm)!"])},
    "Error03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le point de pivot ne peut pas être inférieur à 110 mm !"])}
  },
  "Zijspeling": {
    "Afstand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeu entre mur et porte coulissante"])}
  },
  "Greeptype": {
    "Hoekgreep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poignée d'angle"])},
    "AMURSQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poignée AMUR SQ"])},
    "AMT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poignée AMT"])},
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette poignée est trop grande pour cette porte."])}
  },
  "Maten": {
    "MetMiddenslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poignée d'angle avec serrure au milieu"])}
  },
  "Glassoort": {
    "Klaar10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clair (10 mm)"])},
    "Grijs10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gris (10 mm)"])},
    "Brons10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bronze (10 mm)"])},
    "AcideKlaar10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acide Clair (10 mm)"])},
    "AcideExtraKlaar10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acide Extra Clair (10 mm)"])},
    "Kathedraal8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kathedraal (8 mm)"])},
    "Raywall8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raywall (8 mm)"])},
    "Klaar06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clair (6 mm)"])},
    "ExtraKlaar06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Clair (6 mm)"])},
    "Grijs06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gris (6 mm)"])},
    "Brons06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bronze (6 mm)"])},
    "AcideKlaar06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acide Clair (6 mm)"])},
    "AcideExtraKlaar06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acide Extra Clair (6 mm)"])}
  },
  "Slot": {
    "SlotX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serrure X (mm)"])},
    "0Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans serrure"])},
    "1Slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec serrure"])},
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La serrure est montée dans la plinthe basse. Pour ouvrir et verrouiller la serrure vous devrez vous accroupir."])},
    "0SlotPot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans bague de serrure"])},
    "1SlotPot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec bague de serrure"])},
    "0CilinderKleur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anodisé noir"])},
    "1CilinderKleur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chrome poli"])},
    "0CilinderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serrures identiques"])},
    "1CilinderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serrures différentes"])},
    "0Middenslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poignée sans serrure au milieu"])},
    "1Middenslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poignée avec serrure au milieu"])},
    "0Slotplaat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans plaque de verrouillage"])},
    "1Slotplaat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec plaque de verrouillage"])},
    "0Kantschuifslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans verrou coulissant"])},
    "1Kantschuifslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verrou coulissant latéral noir mat"])},
    "2Kantschuifslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verrou coulissant latéral alu mat"])}
  },
  "Opvulvlak": {
    "0Opvulvlak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans surface de rembourrage"])},
    "1Opvulvlak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au bas de la porte"])},
    "2Opvulvlak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au milieu de la porte"])},
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La surface de rembourrage se situe jusqu'à la 1ère subdivision horizontale."])}
  },
  "Knoppen": {
    "Opslaan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder en EGDesign"])},
    "Terug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour vers EGDesign sans sauvegarder"])},
    "Mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "MailVerdouw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commander"])}
  },
  "ErrorAlert": {
    "ErLiepIetsMis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème s'est produit!"])},
    "VulAllesIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez tout correctement!"])},
    "InvalidToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauvaise clé"])}
  },
  "HelpDeurgrepen": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La position horizontale (h) de la poignée est déterminée par la distance entre le milieu de la poignée et le bord extérieur droit du cadre de porte."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La position verticale (v) de la poignée est déterminée par la distance entre le milieu de la poignée et le bord extérieur inférieur du cadre de porte."])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez que la position de la poignée d'angle soit comme sur la visualisation ci-contre, vous devez remplir la valeur suivante pour la position verticale : ½ x longeur de la poignée d'angle + 68"])},
    "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez que la position de la poignée d'angle soit comme sur la visualisation ci-contre, vous devez remplir la valeur suivante pour la position verticale : ½ x longeur de la poignée d'angle"])}
  },
  "HelpDeurmaten": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur de la porte = hauteur panneau fixe."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez tenir compte des jeux recommandés:"])},
    "Deur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte"])},
    "vloer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sol"])},
    "plafond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plafond"])},
    "muur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mur"])},
    "deur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["porte"])},
    "paneel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["panneau"])},
    "Paneel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panneau"])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les dimensions sont les plus petites largeurs et hauteurs de la baie de porte ou vient se placer la porte ou les panneaux."])},
    "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le cas d'une porte double, la largeur de la porte saisie sera doublée."])},
    "RekenvoorbeeldLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://alusta.be/assets/pdf/rekenvb_config_fr.pdf"])},
    "Rekenvoorbeeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez exemple de calcul"])}
  },
  "HelpAsTools": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les largeurs de porte 1010 mm < x ≤ 1200 mm, il est recommandé de commander ASTOOL1 pour régler les vitesses de fin et de fermeture si vous ne disposez pas d&#39;un tournevis plat de longueur 300 mm."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les largeurs de porte 1010 mm < x ≤ 1200 mm, il est recommandé de commander ASTOOL2 pour régler la broche supérieure si vous ne disposez pas d&#39;un hexagone de taille 3 avec une longueur de 300 mm."])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les largeurs de porte 1200 mm < x ≤ 1500 mm, il est recommandé de commander ASTOOL1XL pour régler les vitesses de fin et de fermeture si vous ne disposez pas d&#39;un tournevis à tête plate de longueur 600 mm."])},
    "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les largeurs de porte 1200 mm < x ≤ 1500 mm, il est recommandé de commander ASTOOL2XL pour régler la broche supérieure si vous ne disposez pas d&#39;un hexagone taille 3 de longueur 600 mm."])}
  },
  "HelpDraaipunt": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le point de rotation de la porte (a) est la distance entre le centre de la crapaudine inférieure de la porte et le bord extérieur du cadre. Si vous enregistrez cette configuration dans EGDesign, la valeur a - 19 mm apparaîtra dans le dessin, car ici la distance du point de rotation de la porte (a) est la distance entre le centre de la crapaudine inférieure et le bord extérieur du verre."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour des raisons de rigidité structurelle, nous recommandons de ne pas modifier la distance de pivot proposée. "])}
  },
  "HelpOnderverdeling": {
    "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La position du croisillon vertical est déterminée par la distance entre le centre de la latte et le coté extérieur gauche ou droit du cadre de porte dépendant du sens de rotation choisi. Si vous enregistrez cette configuration dans EGDesign, la valeur v - 19 mm apparaîtra dans le dessin car il s'agit de la distance entre la ligne centrale de la latte de verre et le bord extérieur du verre."])},
    "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La position du croisillon horizontal est déterminée par la distance entre le centre de la latte et le coté inférieur de la porte. Si vous enregistrez cette configuration dans EGDesign, la valeur h - 66 mm apparaîtra dans le dessin car elle définit la distance entre la ligne centrale de la latte de verre et le bord inférieur du verre."])},
    "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard, les croisillons horizontales et verticales sont placées symétriquement par rapport au verre. Les différentes surfaces vitrées entre les croisillons sont donc de même dimension. (A=A=A) (B=B=B)  Avec une subdivision asymétrique, la position est déterminée par rapport au cadre de la porte. Voir ci-dessous:"])},
    "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous voulez que la poignée de porte se trouve au milieu d'une subdivision horizontale, vous pouvez facilement l'ajuster dans EGDesign."])}
  },
  "HelpTrekkermetslot": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La serrure centrale est positionnée au centre de l'extracteur de porte en combinaison avec un extracteur AMURSQ/AMT. Contactez votre revendeur si vous souhaitez positionner la serrure centrale verticalement différemment."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La serrure centrale est positionnée au centre de l'extracteur de porte en combinaison avec un extracteur AMURSQ/AMT. Dans EGDesign, vous pouvez positionner la serrure centrale verticalement différemment de la position de l'extracteur de porte."])}
    }
  },
  "HelpRalkleur": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La couleur RAL 9005 Coatex est la finition Steellook standard. Cette teinte est disponible de stock, sauf rupture de stock."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La couleur RAL souhaitée n’est pas dans le configurateur? Demandez à votre revendeur quelles couleurs RAL sont possibles."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une représentation numérique des couleurs n’est jamais totalement identique à la couleur réelle. Par conséquent, demandez à votre revendeur de voir des échantillons de la couleur et de la finition souhaitée."])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous donner à certains accessoires une couleur et une finition différentes ? C’est possible!"])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La couleur RAL 9005 Coatex est la finition Steellook standard. Cette teinte est disponible de stock, sauf rupture de stock."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La couleur RAL souhaitée n’est pas dans le configurateur? Via EGDesign, il est possible de sélectionner d’autres couleurs RAL."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une représentation numérique des couleurs n’est jamais totalement identique à la couleur réelle. Offrez toujours des échantillons à vos clients."])},
      "Info04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via EGDesign, il est possible d’attribuer aux accessoires une couleur et une finition différentes."])}
    },
    "Error01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez tenir compte que le choix d'une couleur ou finition différente peut allonger considérablement le délai de livraison."])}
  },
  "HelpLakafwerking": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors du choix d’une couleur RAL, il est nécessaire de choisir une finition de laque souhaitée (structuré, mat, brillant)."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour obtenir l’effet d’aspect de l’acier, la laque texturée est recommandée. Demandez à votre concessionnaire des échantillons pour comparer les différentes finitions."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors du choix d’une couleur RAL, il est nécessaire de choisir une finition de laque souhaitée (structuré, mat, brillant)."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour obtenir l’effet d’aspect de l’acier, la laque texturée est recommandée. Offrez à vos clients des échantillons des différentes finitions de laque."])}
    }
  },
  "HelpAnodic": {
    "Particulier": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La collection anodisée se marie parfaitement avec la gamme de produits Alusta."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec la collection anodisée, il n’est pas possible de choisir une finition laquée."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une représentation numérique des couleurs n’est jamais totalement identique à la couleur réelle. Par conséquent, demandez à votre revendeur de voir des échantillons de la couleur et de la finition souhaitée."])}
    },
    "Dealer": {
      "Info01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La collection anodisée se marie parfaitement avec la gamme de produits Alusta."])},
      "Info02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec la collection anodisée, il n’est pas possible de choisir une finition laquée."])},
      "Info03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une représentation numérique des couleurs n’est jamais totalement identique à la couleur réelle. Offrez toujours des échantillons à vos clients."])}
    }
  },
  "OfferteFormulier": {
    "MainTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
    "MainTitleVerdouw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données de client"])},
    "SubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions sur cette configuration Alusta?"])},
    "voornaam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "naam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "straat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
    "gemeente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
    "welkeDealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par quel distributeur nous avez-vous trouvés ?"])},
    "bedankt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous remercions de votre demande. Nous vous contacterons dès que possible!"])}
  },
  "SelecteerKleur": {
    "structuur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structuré"])},
    "mat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mat"])},
    "glanzend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brillant"])}
  },
  "ColorList": {
    "Default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 9005 structure Noir foncé"])},
    "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre couleur ou finition"])},
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 9005 Noir foncé"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 9016 Blanc signalisation"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 9010 Blanc pur"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 7016 Gris anthracite"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAL 7021 Gris noir"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Natura"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Saphire"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Malachite"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Champagne"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Gold"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Bronze"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANODIC Brown"])}
  },
  "Error": {
    "glasstock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "Referenties": {
    "MainTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Références de commande"])},
    "KlantRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre référence de commande"])},
    "Referentie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre référence de la ligne de commande"])}
  },
  "Pricing": {
    "btw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcul du prix hors TVA et frais d'installation"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-total"])},
    "frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit Alusta (porte/panneau)"])},
    "lak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplément peinture"])},
    "horizontalDividers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croissillons horizontales"])},
    "verticalDividers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croissillons verticales"])},
    "cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surface de remblage"])},
    "AMT200300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT poignée 300mm"])},
    "AMT200500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT poignée 500mm"])},
    "AMT200700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT poignée 700mm"])},
    "AMT201200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMT poignée 1200mm"])},
    "AMUR200300SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ poignée 300mm"])},
    "AMUR200500SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ poignée 500mm"])},
    "AMUR201200SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ poignée 1200mm"])},
    "AMUR302000SQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMUR SQ poignée 2000mm"])},
    "ASCS01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASCS01 serrure avec gâche et 3 clés"])},
    "ASHG01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG01 poignée d'angle 150mm"])},
    "ASHG1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG1200 poignée d'angle 1200mm"])},
    "ASHG2000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG2000 poignée d'angle 2000mm"])},
    "ASHG300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG300 poignée d'angle 300mm"])},
    "ASHG500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHG500 poignée d'angle 500mm"])},
    "ASHGMAAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASHGMAAT poignée d'angle sur mesure"])},
    "ASTOOL1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL1 Alusta outil 1"])},
    "ASTOOL1XL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL1XL Alusta outil 1XL"])},
    "ASTOOL2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL2 Alusta outil 2"])},
    "ASTOOL2XL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASTOOL2XL Alusta outil 2XL"])},
    "V515": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V515 bague de serrure"])},
    "V017S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V017S cylindre à clés identiques comprenant 3 clés"])},
    "V017D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V017D cylindre à cles non identique incluant 3 clés"])},
    "AS522HG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AS522HG poignée d'angle 150mm avec serrure centrale"])},
    "AS522": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AS522 serrure centrale"])},
    "V532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V532 plaque de verrouillage"])},
    "061_7168": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["061.7168 verrou coulissant latéral"])}
  }
}